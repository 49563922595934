// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".title[data-v-5d75aa2c]{display:block;height:140px;font-family:Times New Roman;text-align:center;line-height:160px;font-size:30px}.login_container[data-v-5d75aa2c]{height:100%;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:cover;background-repeat:no-repeat}.login_box[data-v-5d75aa2c]{width:450px;height:400px;background:#fff;border-radius:3px;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%,-50%);-ms-transform:translate(-50%,-50%);transform:translate(-50%,-50%)}.login_form[data-v-5d75aa2c]{position:absolute;bottom:0;width:100%;padding:0 20px;box-sizing:border-box}.btns[data-v-5d75aa2c]{display:-webkit-box;display:flex;-webkit-box-pack:end;justify-content:flex-end}", ""]);
// Exports
module.exports = exports;
