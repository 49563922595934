<template>
  <div class="login_container">
    <!-- 登录盒子  -->
    <div class="login_box">
      <span class="title">知识追寻者</span>
      <!-- 登录表单 -->
      <el-form :model="loginForm" ref="LoginFormRef" class="login_form">
        <!-- 用户名 -->
        <el-form-item label="用户名" prop="username">
          <el-input v-model="loginForm.username" prefix-icon="el-icon-user" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <!-- 密码 -->
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="loginForm.password" prefix-icon="el-icon-lock" placeholder="请输入密码" ></el-input>
        </el-form-item>
        <div
          style="font-size:10px;margin: 5px 0px;cursor:pointer;text-align:right;color:#F56C6C;"
          @click="addDialogVisible=true"
        >用户注册</div>
        <!-- 按钮 -->
        <el-form-item class="btns">
          <el-button size="mini" type="primary" @click="login">登录</el-button>
          <el-button size="mini" type="info" @click="resetLoginForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 用户注册 -->
    <el-dialog title="用户注册" :visible.sync="addDialogVisible" width="30%" @close="addDialogClosed">
      <el-form
        :rules="registerRule"
        :model="addForm"
        ref="addFormRef"
        size="mini"
        label-width="100px"
      >
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username" placeholder="请输入用户名；由数字，字母组成"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="name">
          <el-input v-model="addForm.name" placeholder="请输入昵称"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="addForm.password" placeholder="请输入密码；由数字，字母，特殊字符组成；8位以上密码为佳"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="addForm.email" placeholder="邮箱用于文章审核等功能，请确保能正常接收邮件"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="addDialogVisible = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="register">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { encrypt } from "@/utils/encoder.js";
export default {
  name:'login',
  data() {
    var checkEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请正确填写邮箱"));
      } else {
        if (value !== "") {
          var reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (!reg.test(value)) {
            callback(new Error("请输入有效的邮箱"));
          }
        }
        callback();
      }
    };
    var checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请正确填写密码"));
      } else {
        if (value !== "") {
          var passwordreg = /(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,12}/;
          if (!passwordreg.test(value)) {
            callback(
              new Error("密码必须由数字、字母、特殊字符组合,请输入8-12位")
            );
          }
        }
        callback();
      }
    };
    var checkUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请正确填写用户名"));
      } else {
        if (value !== "") {
          var usernamedreg = /([a-zA-Z0-9]){5,11}/;
          if (!usernamedreg.test(value)) {
            callback(
              new Error("用户名由数字或者字母组成，5-11位")
            );
          }
        }
        callback();
      }
    };
    return {
      loginForm: {
        username: '',
        password: ''
      },
      addForm: {},
      addDialogVisible: false,
      registerRule: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 5, max: 11, message: "长度在 5 到 11 个字符", trigger: "blur" }
          ,
          { validator: checkUsername, trigger: "blur" }
        ],
        name: [
          { required: true, message: "请输入昵称", trigger: "blur" },
          { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 8,
            max: 16,
            message: "长度在 8 到 16 个字符",
            trigger: "blur"
          },
          { validator: checkPassword, trigger: "blur" }
        ],
        email: [
          { required: true, message: "请输入邮箱", trigger: "blur" },
          { validator: checkEmail, trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    // 重置
    resetLoginForm() {
      this.$refs.LoginFormRef.resetFields();
    },
    // 登陆
    async login() {
      const { data: res } = await this.$api.login(this.loginForm);
      if (res.code == 200) {
        await window.localStorage.setItem("token", res.data.access_token);
        await this.$store.dispatch("addUser");
        this.$router.push({ name: 'homepage' , params: { username: encrypt(res.data.username) } })
      } else {
        this.resetLoginForm();
      }
    },
    // 注册
    register() {
      this.$refs["addFormRef"].validate(async valid => {
        if (valid) {
          const { data: res } = await this.$api.register(this.addForm);
          if (res.code == 200) {
            this.addDialogVisible = false;
            this.$alert("注册成功，请等待管理员通过审核后才能登陆", "提示", {
              confirmButtonText: "确定",
              callback: action => {}
            });
          }
        } else {
          this.$alert("注册失败，请联系管理员邮箱lsc_106@126.com", "提示", {
              confirmButtonText: "确定",
              callback: action => {}
            });
          return false;
        }
      });
    },
    addDialogClosed() {
      this.$refs.addFormRef.resetFields();
    }
  }
};
</script>

<style scoped>
.title {
  display: block;
  height: 140px;
  font-family: "Times New Roman";
  text-align: center;
  line-height: 160px;
  font-size: 30px;
}
.login_container {
  /* background-color: #0D559D; */
  height: 100%;
  background-image: url("../assets/img/background.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.login_box {
  width: 450px;
  height: 400px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.login_form {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
}
.btns {
  display: flex;
  justify-content: flex-end;
}
</style>